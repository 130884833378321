import React, { useState, useRef } from "react";
import {
  Flex,
  Text,
  Circle,
  Spinner,
  Card,
  Button,
  DrawerFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  Icon,
} from "@chakra-ui/react";
import { OptionsButton } from "./DynamicButton";
import { WarningIcon } from "@chakra-ui/icons";
import PaymentConfirmation from "./PaymentConfirmation";
import { renderMessageWithLinks, titleCase } from "../../utils/functions";
import { AiFillCheckCircle } from "react-icons/ai";
import { generateReceipt } from "../Content/pdfMaker";
import Footer from "./Footer";
import { handleGenerateStatements } from "../../utils/api";
import { createFileName, useScreenshot } from "use-react-screenshot";
import html2canvas from "html2canvas";
import ShareScreenshot from "./ShareScreenshot";
import { FaDownload, FaShareAlt } from "react-icons/fa";
import { useEffect } from "react";

const ConfirmModal = ({
  isToOpen,
  handleCloseThisModal,
  updating,
  setIsToOpen,
  orgName,
  paymentStatus,
  amount,
  paidBy,
  phoneNumberBy,
  transactionCode,
  colorMain,
  handleCardClick,
  handlePaymentRecheck,
  handleRepay,
  name,
  selection,
  enabled,
  customMessage,
  payType,
  downloadMessage,
  details,
  downloadPdf,
  collection_type,
  toast,
  footerData,
}) => {
  const [processing, setProcessing] = useState(false);

  const ref = useRef(null);
  const refs = useRef(null);

  const [image, takeScreenshot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  });

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [downloading, setIsDownloading] = useState(false);
  const [receipt, setReceipt] = useState(true);

  const getImage = async () => {
    if (receipt) {
      setReceipt(false);
      return;
    }

    setIsDownloading(true);

    try {
      const dataUri = await takeScreenshot(refs.current);
      downloadImage(dataUri);
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }

    setIsDownloading(false);
    setReceipt(true);
  };

  useEffect(() => {
    if (!receipt) {
      getImage();
    }
  }, [receipt]);

  const getThisImage = async () => {
    setIsDownloading(true);
    try {
      await takeScreenshot(refs.current);
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
    setIsDownloading(false);
  };

  const downloadImage = (dataUri) => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href = dataUri;
    link.download = "receipt.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  return (
    <Drawer
      isOpen={isToOpen}
      placement="bottom"
      // isOpen={true}
      closeOnOverlayClick={false}
      onClose={() => {
        handleCloseThisModal(setIsToOpen);
      }}
      isCentered
      size={["xl", "xl"]}
      display="flex"
      alignItems="center"
      flexDir="column"
      p={8}
      bg={colorMain}
    >
      <DrawerOverlay bg="#062a30bb" />
      <DrawerContent
        bg="white"
        shadow="base"
        borderTopRightRadius={12}
        borderTopLeftRadius={12}
      >
        <DrawerHeader> </DrawerHeader>
        {!updating && !processing && (
          <DrawerCloseButton
            size={20}
            fontWeight="bold"
            p={4}
            color={colorMain}
          />
        )}
        <DrawerBody mb={2} mt={2}>
          {updating ? (
            <Flex
              h="100px"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="white"
                size="xl"
              />
              <Text fontSize="md">Checking payment...</Text>
            </Flex>
          ) : (
            <Flex flexDir="column" p={3} ref={refs}>
              <Card
                bg="white"
                shadow="2xl"
                color={colorMain}
                display="flex"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
                borderTopRadius="2xl"
                borderBottomRadius="none"
                pt={3}
                mt={12}
                position="relative"
                borderWidth=".001rem"
                borderBottom={paymentStatus && "none"}
                borderColor="#cccccc"
              >
                <Circle bg="white" size="24" top="-35px" position="absolute">
                  {paymentStatus ? (
                    <AiFillCheckCircle fontSize="50px" color="#40D195" />
                  ) : (
                    <WarningIcon fontSize="50px" color="orange" />
                  )}
                </Circle>
                <Text
                  fontWeight="bold"
                  textTransform="uppercase"
                  fontSize="3xl"
                  pt={1}
                  mt="20px"
                  pb={0}
                  mb={0}
                >
                  {paymentStatus
                    ? "Payment Complete"
                    : "Payment still updating!"}
                </Text>
                <Text
                  fontWeight="medium"
                  p={1}
                  color="gray"
                  fontSize="1.2rem"
                  pb={4}
                  textAlign="center"
                >
                  {paymentStatus && "Thank you, your payment has been received"}
                </Text>
                {paymentStatus && (
                  <hr
                    style={{
                      padding: "0%",
                      margin: "0%",
                      width: "90%",
                      height: ".083rem",
                      color: "#062a30af",
                      opacity: "0.3",
                    }}
                  />
                )}
              </Card>

              {paymentStatus ? (
                <>
                  <Card
                    bg="white"
                    color={colorMain}
                    pb={6}
                    borderTopRadius="none"
                    borderBottomRadius="2xl"
                    mb={8}
                    // outline={`.2rem solid ${colorMain}`}
                    borderWidth=".001rem"
                    borderTop="none"
                    borderColor="#cccccc"
                  >
                    <Flex
                      flexDir="column"
                      alignItems="center"
                      p={6}
                      py={0}
                      ref={ref}
                      background="transparent"
                    >
                      <Flex
                        display="flex"
                        alignItems="baseline"
                        justifyContent="space-between"
                        pt={2}
                      >
                        <Text fontWeight="bold" color="gray">
                          KES
                        </Text>
                        <Text fontWeight="bold" ml={2} fontSize="6xl">
                          {amount !== undefined
                            ? Number(amount).toFixed(2)
                            : "1.00"}
                        </Text>
                      </Flex>
                      <hr
                        style={{
                          padding: "0%",
                          margin: "0%",
                          width: "90%",
                          height: ".033rem",
                          color: "#062a30af",
                          opacity: "0.025",
                        }}
                      />
                      <PaymentConfirmation
                        title="Paid by:"
                        content={titleCase(paidBy || "Anonymous")}
                      />
                      <PaymentConfirmation
                        title="From"
                        content={phoneNumberBy || "2547********"}
                      />
                      {transactionCode && (
                        <PaymentConfirmation
                          title={
                            payType === "card" ? "Paystack code" : " Mpesa code"
                          }
                          content={transactionCode}
                        />
                      )}
                      <Flex
                        w="100%"
                        alignItems="center"
                        mb={6}
                        justifyContent="center"
                      >
                        <hr
                          style={{
                            padding: "0%",
                            margin: "0%",
                            width: "95%",
                            height: ".083rem",
                            color: "#062a30af",
                            opacity: "0.31",
                          }}
                        />
                      </Flex>
                    </Flex>

                    {enabled ? (
                      <Flex alignItems="center" justifyContent="center">
                        <Button
                          bgColor={colorMain}
                          shadow="sm"
                          px={12}
                          py={7}
                          color={receipt ? "white" : `${colorMain}`}
                          borderRadius="md"
                          fontSize={["xl", "2xl"]}
                          fontWeight="bold"
                          isDisabled={processing}
                          isLoading={processing}
                          loadingText="processing.."
                          onClick={() => {
                            if (
                              downloadMessage &&
                              downloadMessage
                                .toLowerCase()
                                .includes("statement" || "statements")
                            ) {
                              handleGenerateStatements({
                                details,
                                number: phoneNumberBy,
                                paid_by: paidBy,
                                toast,
                                downloadPdf,
                                collection_type,
                                setProcessing,
                              });
                            } else {
                              // generateReceipt({
                              //   phoneNumberBy: phoneNumberBy,
                              //   paidBy: paidBy,
                              //   amount: amount,
                              //   transactionCode: transactionCode,
                              //   orgName: orgName,
                              //   selection: selection,
                              //   setProcessing,
                              // });
                              window.open(`/receipt/${transactionCode}`, "_blank");
                            }
                          }}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            gap={2}
                          >
                            {downloadMessage || "Download Receipt"}
                          </Flex>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex alignItems="center" justifyContent="center">
                        <Text
                          fontWeight="semibold"
                          textAlign="center"
                          fontSize={["revert", "2xl"]}
                        >
                          {customMessage
                            ? renderMessageWithLinks(customMessage)
                            : "Thank You!"}
                        </Text>
                      </Flex>
                    )}
                  </Card>
                </>
              ) : (
                <>
                  <DrawerFooter
                    borderTopWidth="1px"
                    alignItems="center"
                    justifyContent="center"
                    gap={3}
                  >
                    <OptionsButton
                      colorMain={colorMain}
                      buttonText="Okay"
                      isLoading={updating ? true : false}
                      isDisabled={updating}
                      onClick={() => {
                        handleCloseThisModal(setIsToOpen);
                      }}
                    />
                  </DrawerFooter>
                </>
              )}
            </Flex>
          )}
          {paymentStatus && (
            <Flex alignItems="center" justifyContent="center">
              <Flex alignItems="center" justifyContent="center" gap={2}>
                <Button
                  border="1px solid"
                  borderColor={colorMain}
                  background="transparent"
                  shadow="sm"
                  px={5}
                  py={6}
                  color={colorMain}
                  borderRadius="xl"
                  fontSize={["xl", "2xl"]}
                  fontWeight="bold"
                  isDisabled={downloading}
                  isLoading={downloading}
                  loadingText="working on it..."
                  // onClick={() => {
                  //   getThisImage();
                  //   setShareModalOpen(true);
                  // }}
                  onClick={() => getImage()}
                >
                  <Icon as={FaDownload} color={colorMain} fontSize="xl" />
                  &nbsp;&nbsp;Save screenshot
                </Button>
              </Flex>

              {shareModalOpen && (
                <ShareScreenshot
                  isOpen={shareModalOpen}
                  onClose={() => setShareModalOpen(false)}
                  image={image}
                  downloadScreenshot={getImage}
                  downloading={downloading}
                />
              )}
            </Flex>
          )}
        </DrawerBody>
        <Flex bg="white" pt="3">
          {footerData?.adshow && <Footer footerData={footerData} />}
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};

export default ConfirmModal;
