import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import FourOFour from "./components/404";
import theme from "./constants/themes";
import ReceiptDownload from "./components/ReceiptDownload";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FourOFour />}></Route>
          <Route path="/:trackCode" element={<App />} />
          <Route path="/:trackCode/:urlParams" element={<App />} />
          <Route path="/:trackCode/*" element={<App />} />
          <Route path="/receipt/:mpesaCode" element={<ReceiptDownload />} />
          <Route path="*" element={<FourOFour />}></Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
